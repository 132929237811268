import { Button, Modal, Input, message } from 'antd';
import React, { useState } from 'react';
import { generateAiText } from './generateAiText';
import ToneSelect from './toneSelect';

function AiTextButton({
    isOpen,
    setIsOpenAi,
    showAiText,
    setAiModalVisibility,
    aiModalVisibility,
    summaryContent = null,
}) {
    const isMobile = window.screen.width < 768;
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({
        input: "",
        tone: "Formal",
    });

    const handleSubmit = async () => {
        setIsLoading(true);

        if (!state.input && !summaryContent) {
            message.error("Please specify what you'd like to generate.");
            setIsLoading(false);
            return;
        }

        if (!state.tone) {
            message.error("Please select a tone.");
            setIsLoading(false);
            return;
        }

        const aiQuestion = summaryContent
            ? `Write a summary covering the following points: "${summaryContent}". ${
                  state.input ? `Also include these additional parameters: ${state.input}.` : ""
              } Maximum of 60 words.`
            : `Write a paragraph covering the following points: "${state.input}" in the ${state.tone} tone. Maximum of 200 words.`;

        try {
            const response = await generateAiText(aiQuestion);
            if (response.status) {
                setIsOpenAi(false);
                showAiText(response.data);
            } else {
                message.error(response.message || "Failed to generate text.");
            }
        } catch (error) {
            message.error("An error occurred while generating text.");
            console.error(error);
        } finally {
            setIsLoading(false);
            setAiModalVisibility(false);
            setState({ input: "", tone: "Formal" });
        }
    };

    const handleCancel = () => {
        setAiModalVisibility(false);
        setState({ input: "", tone: "Formal" });
    };

    return (
        <div>
            <Modal
                title="Generate with AI"
                onCancel={handleCancel}
                visible={aiModalVisibility}
                footer={false}
                zIndex={9999}
            >
                <Input
                    placeholder={summaryContent ? "Additional parameters" : "What would you like to write"}
                    value={state.input}
                    onChange={(e) => setState({ ...state, input: e.target.value })}
                />
                <ToneSelect setTone={(tone) => setState({ ...state, tone })} />
                <Button
                    loading={isLoading}
                    htmlType="submit"
                    style={{
                        color: "#fff",
                        backgroundColor: "#526bb1",
                        width: "max-content",
                        marginTop: "10px",
                        marginLeft: isMobile ? "75%" : "80%",
                        borderRadius: "5px",
                    }}
                    onClick={handleSubmit}
                >
                    Generate
                </Button>
            </Modal>
        </div>
    );
}

export default AiTextButton;