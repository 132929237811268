import React from 'react';
import './App.css';
import "antd/dist/antd.css";
import Header from './components/Header/Header';
import { useSelector } from 'react-redux';
import { Toolbar } from './components/Toolbar/Toolbar';
import Headers from "./components/Headers/Headers";
import RichTextEditor from './components/DraftEditor/Editor';
import { ChatBox } from './components/ChatBox/ChatBox';
import { AuthenticateDocument } from './AuthenticateDocument';
import { useState } from 'react';
import MouseContainer from './components/mousecontainer';
import OfflineStatus from './components/OfflineStatus/OfflineStatus';
import PlaygroundApp from './components/lexical';



function App() {

  const editorIdArr = useSelector((state) => state.editor.editorInstancesIds);
  const isReadOnly = useSelector((state) => state.editor.isReadOnly);
  const isMobile = window.screen.width < 769;

  // const dispatch = useDispatch();
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  if (isAuthenticating) { // isAuthenticating
    return <AuthenticateDocument setIsLoading={setIsAuthenticating} />
  }
  else {
    return (
      <div className="App">
        <Header isMobile={isMobile} />
        <div className='offlineStatusBar'>
          <OfflineStatus />
        </div>
        {/* <Toolbar /> */}
        <div style={{ display: "flex", paddingLeft: 80, marginTop: isReadOnly ? "-55px" : "45px" }}>
          <div className='summary-container'>
            <Headers isMobile={isMobile} />
          </div>
          <div className='EditorContainer' style={{ marginTop: 30 }}>
            {
              editorIdArr.map((id) => {
                return (
                  <>
                    <PlaygroundApp></PlaygroundApp>
                  </>)
              })
            }

            {/* <Editor key={id} quillId={id} /> */}
            {/* <RichTextEditor isMobile={isMobile} key={id} editorId={id} /> */}
            <div className='mouseContainer'>
              <MouseContainer />
            </div>
          </div>
        </div>
        <div className='chatbox-container'>
          <ChatBox />
        </div>
      </div>
    );
  }

}

export default App;
