import React, { useState, useEffect, useRef } from 'react';
import myheaders from './myHeaders.css'
import { useSelector, useDispatch } from "react-redux";
import { useParams, useSearchParams } from 'react-router-dom';
import { Input, Button, message, } from 'antd';
import { PlusOutlined, EditOutlined, ArrowLeftOutlined, EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import { debounce } from "lodash";
import { padServices } from '../../api';
import { ReactComponent as Ai } from '../../assets/ai_post.svg';
import { generateAiText } from '../AiTextButton/generateAiText';
import { updateIsSaving } from '../../app/EditorSlice';
import AiTextButton from '../AiTextButton/AiTextButton';
const { TextArea } = Input;

const Headers = ({ isMobile }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isOpenOutline, setIsOpenOutline] = useState(true);
  const [summaryText, setSummaryText] = useState('');
  const [searchParams] = useSearchParams();
  const documentName = searchParams.get('name');
  const quillDelta = useSelector((state) => state.editor.editorInstancesDelta);
  const editorContent = useSelector((state) => state.editor.editorInstancesDelta); // Change the selector to match the Draft.js editor content
  const isReadOnly = useSelector((state) => state.editor.isReadOnly);
  const docTitle = useSelector((state) => state.editor.docTitle);
  const editorState = useSelector((state) => state.editor.editorState);
  const editorRef = useSelector((state) => state.editor.editorRef);
  const [headings, setHeadings] = useState([]);
  const summaryRef = useRef();
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [aiModalVisibility, setAiModalVisibility] = useState(false);
  const [isOpenAi, setIsOpenAi] = useState(false);

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(editorContent, 'text/html');
    const headingElements = doc.querySelectorAll('h1, h2, h3, h4, h5, h6');
    const extractedHeadings = Array.from(headingElements).map((element) => ({
      level: parseInt(element.tagName[1], 10),
      text: element.textContent.trim(),
      id: element.getAttribute('id'),
    }));

    setHeadings(extractedHeadings);
    // console.log('headingsArray', headings);
  }, [editorContent]);

  const fetchSummary = async () => {
    const response = await padServices.fetchById(id);
    if (response.data?.milepadID && response.data?.summary != null) {
      setSummaryText(response.data?.summary)
    }
  }
  React.useEffect(() => {
    fetchSummary();
    return () => {
      debouncedSaveToDatabase.cancel();
    };
  }, [id]);

  const debouncedSaveToDatabase = useRef(debounce((summary) => {
    padServices.saveToDatabase(id, null, summary, dispatch);
  }, 800)).current;

  const handleHeadingClick = (event, headingId) => {
    event.preventDefault();
    if (editorRef.current) {
      // editorRef.current.focus();
    }
  };

  const handlePlusClick = () => {
    setIsEditMode(true);
    summaryRef.current.focus();
  };

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleTextareaChange = (e) => {
    setSummaryText(e.target.value);
    dispatch(updateIsSaving({ isSaving: 'Saving...', lastSavedDateTime: '' }));
    debouncedSaveToDatabase(e.target.value);
  };

  const handleBlur = () => {
    setIsEditMode(false);
  };

  const handleCloseClick = () => {
    setIsOpenOutline(false);
  };

  const handleOpenClick = () => {
    setIsOpenOutline(true);
  };

  const handleAiSummary = async () => {
    setSummaryLoading(true);
    try {
      const aiQuestion = `Write a summary of covering the following points "${editorContent}." Maximum of 60 words`;
      const response = await generateAiText(aiQuestion);

      if (response.status) {
        setSummaryText(response.data);
        padServices.saveToDatabase(id, null, response.data, dispatch);
        message.success("Summary generated successfully!");
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error("An error occurred while generating the summary.");
    } finally {
      setSummaryLoading(false);
    }
  };
  const showAiText = (data) => {
    if (data) {
      setSummaryText(data);
      padServices.saveToDatabase(id, null, data, dispatch);
    }
  }
  const renderSummaryText = () => {
    if (!isEditMode) {
      if (!summaryText) {
        return (
          <div style={{ minWidth: '200px' }}>
            <Button
              title='Add summary'
              style={{ float: 'right', marginTop: '-36px' }}
              type="text"
              icon={<PlusOutlined style={{ fontSize: '18px' }} />}
              onClick={handlePlusClick}
              disabled={isReadOnly ? true : false}
            />
          </div>
        );
      }
      else {
        return (
          <>
            <Button
              type="text"
              title='Edit summary'
              icon={<EditOutlined />}
              onClick={handleEditClick}
              style={{ float: "right", marginTop: '-2.4rem' }}
              disabled={isReadOnly ? true : false}
            />
            <br />
            <TextArea
              style={{
                resize: 'none',
                maxHeight: '120px',
                pointerEvents: 'none',
              }}
              autoSize={{ minRows: 2 }}
              value={summaryText}
              readOnly
            />

          </>
        );
      }
    }
  };
  return (
    <>
      {isOpenOutline ? (
        <>
          <div style={{ width: isMobile ? '120px' : '200px', display: 'flex', justifyContent: 'space-between', position: isMobile ? 'absolute' : '', top: isMobile ? '232px' : '', left: isMobile ? '25px' : '' }}>
            <Button style={{ marginTop: isMobile ? '70' : '55px' }} title='Close document outline' type="text" icon={<ArrowLeftOutlined onClick={handleCloseClick} />} />
            <div>
              {summaryLoading && <LoadingOutlined style={{ marginRight: 10 }} />}
              {/* <Button style={{ marginTop: '55px' }} title='Generate Summary with AI' type="text" icon={<Ai onClick={handleAiSummanry} />} /> */}
            </div>
          </div>
          <div className={myheaders.summaryContainer} style={{ overflowY: 'scroll', height: '820px', position: isMobile ? 'relative' : '', width: isMobile ? '140px' : '140px', top: isMobile ? '80px' : '', left: isMobile ? '-65px' : '', width: 'fit-content' }} >
            <span>
              <h3 style={{ marginLeft: isMobile ? "20px" : '', marginTop: isMobile ? "20px" : '' }} className={myheaders.summary}>Summary </h3>
              {!isEditMode ? (
                renderSummaryText()
              ) : (
                <TextArea
                  className={myheaders.textAreaSummary}
                  autoSize={{ minRows: 2 }}
                  value={summaryText}
                  onChange={handleTextareaChange}
                  onBlur={handleBlur}
                  ref={summaryRef}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      setIsEditMode(false);
                    }
                  }}
                />
              )}
            </span>
            <h1 style={{ fontSize: 22, fontWeight: '500' }}>{documentName}</h1>
            <hr />
            <h3 style={{ fontSize: 18, textTransform: 'uppercase', fontWeight: '400', marginLeft: isMobile ? '10px' : '' }}>Outline</h3>
            {headings.length > 0 ? (
              headings.map((heading, index) =>
                heading.text.trim() !== '' &&
                <div
                  key={index}
                  style={{ marginLeft: `${(heading.level - 1) * 10}px`, marginTop: '4px', cursor: 'pointer', width: '200px', wordWrap: 'break-word' }}
                  onClick={(event) => handleHeadingClick(event, heading.id)}
                >
                  <span style={{ fontWeight: heading.level === 1 ? '600' : 'normal' }}>{heading.text}</span>
                </div>

              )
            ) : (
              <h1 style={{ fontSize: 18, fontWeight: '300', fontStyle: 'italic', width: "240px" }}>
                Headings that you add to the document will appear here.
              </h1>
            )}

          </div>
        </>
      ) : (
        <EyeOutlined title='Show document outline' style={{ position: isMobile ? 'absolute' : '', left: isMobile ? '30px' : '', fontSize: '20px', marginTop: isMobile ? '75px' : '55px' }} onClick={handleOpenClick} />
      )}
      <div>
        <Button
          style={{ top: '215px', left: '242px' }}
          className="AI-icon"
          title="Generate with AI"
          type="text"
          icon={
            <Ai style={{ width: '26px' }} />
          }
          onClick={()=>setAiModalVisibility(true)}
        />
        <AiTextButton
          summaryContent={editorContent}
          isOpen={isOpenAi}
          setIsOpenAi={setIsOpenAi}
          showAiText={showAiText}
          setAiModalVisibility={setAiModalVisibility}
          aiModalVisibility={aiModalVisibility}
        />
      </div>
    </>
  );
};

export default Headers;